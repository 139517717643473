import { Decorations } from './decorations'
import { Referrals } from './referrals'
import { Empty } from './empty'
import { OnBoarding } from './onboarding'

export const images = {
    Decorations,
    Referrals,
    Empty,
    OnBoarding,
}