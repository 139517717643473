export const data: {
    smile: string
    rate: number
}[] = [
    {
        smile: '😢',
        rate: 1,
    },
    {
        smile: '',
        rate: 2,
    },
    {
        smile: '😐',
        rate: 3,
    },
    {
        smile: '',
        rate: 4,
    },
    {
        smile: '😀',
        rate: 5,
    },
]