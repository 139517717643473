import Leader from './leader.png'
import Message from './message.png'
import NLO from './nlo.png'
import Notepad from './notepad.png'
import Avatar from './avatar.png'

export const Empty = {
    Leader,
    Message,
    NLO,
    Notepad,
    Avatar,
}